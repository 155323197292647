/**
 * Login Page
 */
export const TRACKING_ID_LOGIN_LOGIN = 'login_form_submit'
export const TRACKING_ID_LOGIN_GOOGLE = 'login_form_google_login'
export const TRACKING_ID_LOGIN_FACEBOOK = 'login_form_facebook_login'
export const TRACKING_ID_LOGIN_LINKEDIN = 'login_form_linkedin_login'
/**
 * Header
 */
export const TRACKING_ID_HEADER_LOGIN = 'header_login_button'
export const TRACKING_ID_HEADER_CANDIDATE_REGISTER = 'header_register_candidate_button'
/**
 * Footer
 */

export const TRACKING_ID_LP_FOOTER_REGISTER_LINK = 'footer_register_link'
export const TRACKING_ID_LP_FOOTER_LOGIN_LINK = 'footer_login_link'

/**
 * Candidate Job Detail Page
 */

export const TRACKING_ID_CANDIDATE_JOB_DETAIL_APPLY_BUTTON = 'job_detail_apply_button'
export const TRACKING_ID_CANDIDATE_REGISTER = 'register_form_submit'
export const TRACKING_ID_CANDIDATE_QUICK_APPLY_GOOGLE_BUTTON = 'candidate_quick_apply_google_button'
export const TRACKING_ID_CANDIDATE_QUICK_APPLY_FACEBOOK_BUTTON = 'candidate_quick_apply_facebook_button'
export const TRACKING_ID_CANDIDATE_REGISTER_GOOGLE_BUTTON = 'candidate_register_google_button'
export const TRACKING_ID_CANDIDATE_REGISTER_FACEBOOK_BUTTON = 'candidate_register_facebook_button'
export const TRACKING_ID_CANDIDATE_QUICK_APPLY_SUBMIT = 'candidate_quick_apply_submit_job'
export const TRACKING_ID_CANDIDATE_APPLY_BUTTON = 'candidate_apply_submit_job'

/**
 * Landing Page
 */
export const TRACKING_ID_LP_FOOTER_BOOK_CONSULTANT_BUTTON = 'lp_footer_book_consultant'
export const TRACKING_ID_LP_PRODUCT_BOOK_A_CONSULTATION_BTN = 'button_book_a_consultation_3'
export const TRACKING_ID_LP_HEADER_LOGIN = 'lp_header_login_button'
export const TRACKING_ID_LP_HEADER_CONTACT_US = 'lp_header_contact_us_button'
export const TRACKING_ID_LP_HEADER_POST_JOB_FOR_FREE = 'lp_header_post_job_for_free'

/**
 * Employeer onboarding
 */

export const TRACKING_ID_EMPLOYEER_ONBOARDING_SUBMIT_BUTTON = 'emplloyee_onboarding_subbmit_button'
export const TRACKING_ID_EMPLOYEER_REGISTER = 'employee_regiter_submit_button'
export const TRACKING_ID_EMPLOYEER_CONTACT_SUBMMIT = 'employee_contact_submit'
export const TRACKING_ID_LP_TO_PRODUCT_HEADHUNT_SERVICE = 'employee_lp_to_headhunt_service'
