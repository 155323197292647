import css from 'styled-jsx/css'

export const ANTDInputStyle = css.global`
  body .ant-input {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
  }

  body .ant-input-affix-wrapper {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
  }
`
