import { useEffect, useState } from 'react'
import { isMoblie, useServerService } from '../components/FreecProvider'
import { FORM_PRODUCT } from '../utilities/common'
import { getIsServer } from '../utilities/SSR'

const MOBILE_MEDIA_BREAK_POINT = 768
export const useDimention: () => { isMobile: boolean; fromProduct: number } = () => {
  const serverService = useServerService()
  const [isMobile, setMobile] = useState<boolean>(getIsServer() ? serverService?.isMobile : isMoblie())
  useEffect(() => {
    const handleResizeComponent = () => {
      if (isMobile) {
        if (window.innerWidth > MOBILE_MEDIA_BREAK_POINT) {
          setMobile(false)
        }
      } else {
        if (window.innerWidth <= MOBILE_MEDIA_BREAK_POINT) {
          setMobile(true)
        }
      }
    }
    window.addEventListener('resize', handleResizeComponent)
    return () => {
      window.removeEventListener('resize', handleResizeComponent)
    }
  }, [isMobile])
  return {
    isMobile: isMobile,
    fromProduct: isMobile ? FORM_PRODUCT.WEB_MOBILE : FORM_PRODUCT.WEB_DESKTOP
  }
}
