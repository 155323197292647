import React, { FC } from 'react'
import { Modal } from 'src/@legacy/@core/components/share/ANTD/Modal'
import { RegisterSocialStyle } from 'src/@legacy/style/styled/Modal/RegisterSocialStyle.style'
import { UserAvatarView } from 'src/@legacy/@core/components/Avatar/ImageViewer'
import { Form, useForm } from 'src/@legacy/@core/components/share/ANTD/Form'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { Input } from 'src/@legacy/@core/components/share/ANTD/Input'
import { Button } from 'src/@legacy/@core/components/share/ANTD/Button'

const RegisterSocialModal: FC<{ open; onOk; onFinish; loading; dataPayload }> = ({ open, onOk, onFinish, loading, dataPayload }) => {
  const { translate } = useTranslation()
  const [form] = useForm()
  return (
    <div>
      <style jsx>{RegisterSocialStyle}</style>
      <Modal width={384} centered visible={open} footer={null} onCancel={onOk} onOk={onOk} className="register-social">
        <div className="d-flex align-items-center">
          <div>
            <UserAvatarView fullName={dataPayload?.full_name} size={80} src={dataPayload?.photo_url} />
          </div>
          <div className="register-info">
            <div className="user-name">{dataPayload?.full_name}</div>
            <div className="email">{dataPayload?.email}</div>
          </div>
        </div>
        <div className="content">{translate('form:content_register_social')}</div>
        <Form form={form} name="register_social" onFinish={onFinish} layout="vertical">
          <Form.Item
            className="mb-3"
            label={translate('label:company_name')}
            name="name"
            rules={[
              { required: true, message: translate('form:validation_company_name') },
              {
                min: 5,
                message: translate('form:validate_range_length', {
                  min: 5,
                  max: 30
                })
              },
              {
                max: 30,
                message: translate('form:validate_range_length', {
                  min: 5,
                  max: 30
                })
              },
              () => ({
                validator(rule, value) {
                  if (value) {
                    if (value.replace(/\s/g, '') === '') return Promise.reject(translate('form:validation_no_space'))
                  }
                  return Promise.resolve()
                }
              })
            ]}>
            <Input placeholder={translate('label:your_company_name')} size="large" />
          </Form.Item>
          <Form.Item
            className="mb-3"
            label={translate('label:phone_number')}
            name="phone"
            rules={[
              {
                min: 9,
                message: translate('form:validate_range_length', {
                  min: 9,
                  max: 15
                })
              },
              {
                max: 15,
                message: translate('form:validate_range_length', {
                  min: 9,
                  max: 15
                })
              },
              { pattern: /^[0-9]*$/, message: translate('form:validate_phone_format') }
            ]}>
            <Input size="large" prefix="+84" id="register_form_phone_number" />
          </Form.Item>
          <Form.Item className="mb-0">
            <Button loading={loading} type="primary" className="btn-submit w-100" htmlType="submit" size="large">
              {translate('button:create_account')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default RegisterSocialModal
