import Router from 'next/router'
import { createAPIClient } from '../@core/utilities/APIClient/CreateApiClient'
import env from './env'
import { LINK_URL_LOGIN } from './LinkURL'
export default createAPIClient(
  env.PUBLIC_ENABLE_API_PROXY ? '/' : env.PUBLIC_API_END_POINT,
  () => {
    return Router.push(LINK_URL_LOGIN)
  },
  () => {
    return Router.push(LINK_URL_LOGIN)
  }
)
