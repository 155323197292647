import { createIdSlug, createNewCompanyIdSlug } from './common'

export const LINK_URL_JOBS = '/jobs'
export const LINK_URL_FORGOT_PASSWORD = '/forgot-password'
export const LINK_URL_UPDATE_PASSWORD = '/update-password'
export const LINK_URL_REGISTER = '/employer/register'
export const LINK_URL_LOGIN = '/login'
export const LINK_URL_ROOT = '/'
export const LINK_URL_BLOG_CATEGORY = 'https://blog.freec.asia/category/nha-tuyen-dung/'
export const LINK_URL_JOB_CATEGORY = (id: number): string => `/jobs?job_category[]=${id}`

export const LINK_URL_DOWNLOAD_APPLE = 'https://apps.apple.com/us/app/freec/id1475879992?l=vi&ls=1'
export const LINK_URL_DOWNLOAD_ANDROID = 'https://play.google.com/store/apps/details?id=com.freec.asia'

export const LINK_URL_COMPANY_CANDIDATES = (companyID: number): string => `/employer/${companyID}/candidates`
export const LINK_KEY_COMPANY_CANDIDATES = `/employer/[companyID]/candidates`
export const LINK_URL_COMPANY_CLIENT = '/employer/client'
export const LINK_URL_COMPANY_CREATE_JOB = (companyID: number): string => `/employer/${companyID}/jobs/create`
export const LINK_KEY_COMPANY_CREATE_JOB = `/employer/[companyID]/jobs/create`
export const LINK_URL_COMPANY_CREATE_JOB_FOR_CLIENT = '/employer/jobs/create-job-for-client'
export const LINK_URL_ONBOARDING = '/employer/onboarding'
export const LINK_URL_ONBOARDING_STEP_1 = '/employer/onboarding/step1'
export const LINK_URL_ONBOARDING_STEP_2 = '/employer/onboarding/step2'
export const LINK_URL_COMPANY_JOB_LIST = (companyID: number): string => `/employer/${companyID}/jobs`
export const LINK_KEY_COMPANY_JOB_LIST = `/employer/[companyID]/jobs`
export const LINK_URL_COMPANY_EDIT_JOB = (companyID: number, id: number | string): string => `${LINK_URL_COMPANY_JOB_LIST(companyID)}/${id}`
export const LINK_KEY_COMPANY_EDIT_JOB = `${LINK_KEY_COMPANY_JOB_LIST}/[id]`
export const LINK_URL_COMPANY_JOB_DETAIL = (companyID: number, id: number | string): string =>
  `${LINK_URL_COMPANY_JOB_LIST(companyID)}/${id}/detail`

export const LINK_KEY_COMPANY_JOB_DETAIL = `${LINK_KEY_COMPANY_JOB_LIST}/[id]/detail`
export const LINK_URL_COMPANY_JOB_PREVIEW = (companyID: number, id: number | string): string => `/employer/${companyID}/${id}/preview`
export const LINK_KEY_COMPANY_JOB_PREVIEW = `/employer/[companyID]/[jobID]/preview`
export const LINK_KEY_CONSULTANT_JOB_PREVIEW = `/consultants/review-jobs/[id]`
export const LINK_URL_LP_HEADHUNT_SERVICE = '/employer/headhunt-service'
export const LINK_URL_WAIT_VERIFICATION = '/employer/register/wait-for-verification'
export const LINK_URL_INVITE_MEMBER = '/account/invite-team-members'
export const LINK_URL_EMAIL_TEMPLATES = '/account/email-templates'

export const LINK_URL_EMPLOYER_PRODUCT = '/employer/product'
export const LINK_URL_EMPLOYER_PRODUCT_SERVICE_PLANS = '/employer/product#service-plans'
export const LINK_URL_EMPLOYER_CLIENT_CREATE = '/employer/client/create-client'
export const LINK_URL_EMPLOYER_CLIENT_ADD_CLIENT = '/employer/client/add-client'
export const LINK_URL_EMPLOYER_CONTACT = '/employer/contact'
export const LINK_URL_CHAT_WITH_SUPPORT = '/chat-with-support'

export const LINK_URL_TERMS = process.env.NEXT_PUBLIC_LINK_URL_TERMS || '/terms-of-service'
export const LINK_URL_POLICY = process.env.NEXT_PUBLIC_LINK_URL_POLICY || '/privacy-policy'

export const LINK_URL_EMAIL_SENT = '/forgot-password/confirmation'
export const LINK_URL_FORGOT_VERIFY_TOKEN = '/forgot-password/reset-password'

export const LINK_URL_ABOUT_FREEC = 'https://freecracy.com/'
export const LINK_URL_CANDIDATES_PLATFORM = 'https://freec.asia/'
export const LINK_URL_LINKIN = 'https://www.linkedin.com/company/freecasia/'
export const LINK_URL_FACEBOOK = 'https://www.facebook.com/freecasiacorp/'
export const LINK_URL_YOUTUBE = 'https://www.youtube.com/channel/UCIb1ZELs5mI5I2T5xTIB-zQ'
export const LINK_JOB_POST_POLICY =
  process.env.NEXT_PUBLIC_LINK_JOB_POST_POLICY || 'https://support.freec.asia/vi/article/quy-dinh-dang-tin-1kpnrdd/?bust=1621588019101'
export const LINK_URL_INSTAGRAM = 'https://www.instagram.com/freec_vn/'

export const LINK_URL_MY_ACCOUNT = '/account/your-account'
export const LINK_URL_VERIFY_MEMBER_UPDATE_PROFILE_INFOR = '/verify-member/update-member-infor'
export const LINK_URL_TOKEN_NOT_FOUND = '/token-not-found'
export const LINK_URL_CANDIDATE_ONBOARDING = '/onboarding'
export const LINK_URL_CANDIDATE_REGISTER = '/register'
export const LINK_URL_BLOG = '/blogs'
export const LINK_URL_MY_JOBS = '/my-jobs'
export const LINK_URL_CANDIDATES_JOB_ALERT = '/account/job-alert'
export const LINK_URL_CANDIDATES_MY_PROFILE = '/my-profile'
export const LINK_URL_CANDIDATES_PUBLISH_PROFILE = '/profiles/[...slugName]'
export const LINK_URL_COMPANY_PUBLISH_JOBS = '/company/[slug]'
export const LINK_URL_COMPANY_PUBLISH_JOBS_SLUG = (slug: string, id: string | number) => `/company/${createNewCompanyIdSlug(slug, id)}`
export const LINK_URL_MY_JOBS_APPLIED = '/my-jobs/applied'
export const LINK_URL_MY_JOBS_SAVED = '/my-jobs/saved'
export const LINK_URL_CANDIDATES_MANAGE_YOUR_CV = '/account/manage-cv'
export const LINK_URL_CANDIDATES_JOB_REFERENCE = '/job-preference'
export const LINK_URL_CANDIDATES_CHANGE_PASSWORD = '/account/change-password'
export const LINK_URL_MANAGE_JOBS = '/employer/manage-jobs'
export const LINK_URL_EMPLOYER_SOURCE = '/employer/source'
export const LINK_URL_EMPLOYER_REGISTER = '/employer/register'
export const LINK_URL_ACCOUNT_COMPANY = '/account/company-account'
export const LINK_URL_RECRUITER_RECREATE_JOBS = (comapyid: number, jobId: number | string): [string, string] => [
  `/employer/[companyID]/jobs/create?from-job-id=${jobId}`,
  `/employer/${comapyid}/jobs/create?from-job-id=${jobId}`
]
export const LINK_URL_COMPANY_DETAIL = (slug: string) => `/company/${slug}/jobs`

export const LINK_URL_CONSULTANT_CLIENT = (id: number | string): string => `/consultants/clients/${id}`
export const LINK_URL_CONSULTANTS_OFFER = '/consultants/offer'
export const LINK_URL_CONSULTANTS_INTERVIEW = '/consultants/interview'
export const LINK_URL_CONSULTANTS_SOURCE = '/consultants/source'
export const LINK_URL_CONSULTANTS_CANDIDATES = '/consultants/candidates'
export const LINK_URL_CONSULTANTS_TALENT_POOL = '/consultants/talent-pool'

export const LINK_URL_CONSULTANTS_CLIENT = '/consultants/clients'
export const LINK_URL_CONSULTANTS_CLIENT_CREATE = '/consultants/clients/create'
export const LINK_URL_CONSULTANTS_CLIENT_ADD_AS_CLIENT = '/consultants/clients/add-as-client'
export const LINK_URL_CONSULTANTS_JOB_ORDERS = '/consultants/job-orders'
export const LINK_URL_CONSULTANTS_JOB_ORDERS_CREATE = '/consultants/job-orders/create'
export const LINK_URL_CONSULTANTS_JOBS = `/consultants/job-orders`
export const LINK_URL_CONSULTANTS_JOBS_EDIT = (jobId: number | string): string => `/consultants/job-orders/${jobId}/update`
export const LINK_KEY_CONSULTANTS_JOBS_EDIT = '/consultants/job-orders/[id]/update'
export const LINK_URL_CONTACT_SUCCESS = '/employer/contact/success'
export const LINK_URL_CONSULTANT_RECREATE_JOBS = (id: number | string): string => `/consultants/job-orders/create?from-job-id=${id}`
export const LINK_URL_CONSULTANT_VIEW_PROFILE = (id: number | string): string => `/consultants/source/${id}/detail`
export const LINK_URL_CONSULTANT_EDIT_PROFILE = (id: number | string): string => `/consultants/source/${id}/update`

export const LINK_URL_CONSULTANT_IFRAME_WHAT_NEWS = '/consultants/what-news'
export const LINK_URL_CONSULTANT_IFRAME_REPORT = '/consultants/report'
export const LINK_URL_CONSULTANT_IFRAME_FEEDBACK = '/consultants/feedback'
export const LINK_URL_CONSULTANT_IFRAME_FAQ = '/consultants/faq'
export const LINK_URL_CONSULTANT_IFRAME_IDEA = ''

export const LINK_URL_ONBOARDING_CANDIDATE_STEP_1 = '/onboarding/step1'
export const LINK_URL_ONBOARDING_CANDIDATE_STEP_2 = '/onboarding/step2'
export const LINK_URL_EMPLOYER_VIEW_APPLICANT = (
  id: number | string | string[],
  jobID: number | string,
  candidatesID: number | string
): string => `/employer/${id}/jobs/${jobID}/applicants/${candidatesID}`
export const LINK_KEY_EMPLOYER_VIEW_APPLICANT = '/employer/[companyID/jobs/[id]/applicants/[applicantId]'
export const LINK_URL_CONSULTANT_VIEW_APPLICANT = (jobID: number | string, candidatesID: number | string): string =>
  `/consultants/job-orders/${jobID}/applicants/${candidatesID}`
export const LINK_KEY_CONSULTANT_VIEW_APPLICANT = '/consultants/job-orders/[id]/applicants/[applicantId]'
export const LINK_VIEW_SHARE_PROFILE = '/candidates/share/[token]'
export const LINK_URL_ABOUT_404 = '/404'
export const LINK_URL_JOB_PUBLISH = (slug: string, id: number): string => `/jobs/${createIdSlug(slug, id)}`
export const LINK_URL_COMPANIES = '/companies'
export const LINK_URL_COMPANIES_WITH_LOCATION = (locationId: string | number) => `/companies?location=${locationId}`
export const LINK_URL_CONSULTANT_PLACEMENT = '/consultants/placement'

export const LINK_URL_CONSULTANT_COMPANIES_DETAIL = (id: number | string | string[]): string => `/consultants/companies/${id}`
export const LINK_URL_CONSULTANT_COMPANIES_DETAIL_PLACEMENT = (id: number | string | string[]): string =>
  `/consultants/companies/${id}?tab=placement`
export const LINK_URL_ADMIN_COMPANIES_DETAIL = (id: number | string | string[]): string => `/admin/companies/${id}`

export const LINK_URL_ADMIN_REVIEW_JOBS = '/review-jobs'
export const LINK_URL_ADMIN_COMPANY = '/companies'
export const LINK_URL_ADMIN_BANNER_ADS = '/banners'
export const LINK_URL_ADMIN_USERS = '/users'
export const LINK_URL_QUICK_APPLY = '/quick-apply'
export const LINK_URL_ADMIN_ADD_CLIENT = (company_id: number): string => `/companies/add-as-client/${company_id}`

export const LINK_URL_COMPANY_PUBLIC_COMPANY = (slug: string) => `${process.env.NEXT_PUBLIC_APP_DOMAIN}/company/${slug}/jobs`
export const LINK_URL_DOWNLOAD_APP = '/download-app'
export const LINK_URL_EDIT_BRANDED_PAGE = (id: number | string | string[]): string => `/company/${id}`
export const LINK_URL_CONSULTANT_TASK = '/consultants/tasks'
