import React, { FC, useState } from 'react'
import style from './Avatar.module.scss'
import { CameraFilled } from '@ant-design/icons'
import { Avatar } from '../share/ANTD/Avatar'
import { AVATAR_DEFAULT, LOGO_DEFAULT } from '../../utilities/Constant'
import Typography from '../share/Typography'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { Spin } from '../share/ANTD/Spin'
import AvatarDefault from './AvatarDefault'
import TooltipVerified from '../TooltipVerifyCompany/TooltipVerifyCompany'

const borderStyle = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#e8e8e8'
}
const L4JFramOver = (Component) => (props) => (
  <div className="d-flex justify-content-center">
    <div style={{ display: 'grid', gridTemplateColumns: 'auto', width: props.size }}>
      <style jsx global>{`
        .avatar-l4j {
          background-image: linear-gradient(to bottom, rgba(54, 186, 126, 0) 61%, #36ba7e 92%);
          border-radius: 9999px;
        }
      `}</style>

      <div style={{ gridColumn: 1, gridRow: 1 }}>
        <Component {...props} />
      </div>
      {props.l4j && (
        <div
          style={{
            gridColumn: 1,
            gridRow: 1,
            width: props.size,
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
          className="avatar-l4j">
          <div
            style={{
              fontSize: 8 * (props.size / 64),
              lineHeight: 'normal',
              right: props.size / 8,
              position: 'relative',
              textAlign: 'center'
            }}>
            #open to
          </div>
          <div
            style={{
              fontSize: 9 * (props.size / 64),
              lineHeight: 'normal',
              position: 'relative',
              textAlign: 'center',
              marginBottom: 1 / (props.size / 64 / 2)
            }}>
            WORK
          </div>
        </div>
      )}
    </div>
  </div>
)
export const LogoCompanyView: FC<{
  src: any
  alt?: string
  title?: string
  className?: string
  isAmp?: boolean
  sizeWidthAmp?: number
  sizeHeightAmp?: number
  size?: number
  radius?: number
  isVerified?: boolean
  l4j?: boolean
}> = ({ src, alt, title, className, isAmp, sizeWidthAmp, sizeHeightAmp, size, radius = 5, isVerified = false }) => {
  const [srcState, setSrc] = useState(src || LOGO_DEFAULT)
  const [error, setError] = useState(false)
  const borderRadius = radius // radius ? radius : size > 100 ? 5 : 0
  const onError = () => {
    if (!error) {
      setError(true)
      setSrc(LOGO_DEFAULT)
    }
  }

  if (isAmp) {
    return (
      <div style={{ position: 'relative' }}>
        {isVerified && <TooltipVerified />}
        <div
          className="d-flex align-items-center"
          style={{
            width: sizeWidthAmp,
            height: sizeHeightAmp || sizeWidthAmp,
            margin: 'auto',
            overflow: 'hidden',
            borderRadius,
            ...borderStyle
          }}>
          <amp-img width={sizeWidthAmp} height={sizeHeightAmp || sizeWidthAmp} src={srcState} alt={alt} title={title} />
        </div>
      </div>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      {isVerified && <TooltipVerified />}
      <div
        style={{ width: size, height: size, margin: 'auto', overflow: 'hidden', borderRadius, ...borderStyle }}
        className="logo-company d-flex align-items-center">
        <img alt={alt} title={title} src={srcState} onError={onError} style={{ maxWidth: '100%' }} className={className} />
      </div>
    </div>
  )
}

export const UserAvatarView: FC<{
  fullName?: string
  src: string
  size: number
  className?: string
  score?: number
  alt?: string
  l4j?: boolean
}> = L4JFramOver(({ src, size, className, score, alt, fullName }) => {
  if (score) {
    return (
      <div className="avatar-container">
        <style jsx>{`
          .avatar-container {
            position: relative;
          }
          .user-avarage-point {
            position: absolute;
            border-radius: 20px;
            bottom: -6px;
            right: 0;
            background: #f5f5f5;
            padding-top: 0px;
            line-height: 16px;
            padding-left: 3px;
            color: #8c8c8c;
          }
        `}</style>
        <Avatar alt={alt || 'avatar'} size={size} shape="circle" className={className} src={src ? src : AVATAR_DEFAULT} />
        <div className="user-avarage-point">
          <Typography.Text nowrap>{Math.round((score + Number.EPSILON) * 10) / 10}</Typography.Text>
        </div>
      </div>
    )
  }
  if (src) {
    return (
      <>
        <style jsx global>{`
          .avatar-logo {
            overflow: hidden;
            border: solid 1px #f8f8f8;
          }
        `}</style>
        <div className={`d-flex justify-content-center ${className}`}>
          <div
            style={{ width: size, height: size, borderRadius: size / 2 }}
            className="d-flex justify-content-center align-items-center avatar-logo">
            <img style={{ maxWidth: '100%' }} src={src} />
          </div>
        </div>
      </>
    )
    // return <Avatar alt="avatar" size={size} shape="circle" className={className} src={src ? src : AVATAR_DEFAULT} />
  }

  return <AvatarDefault name={fullName} size={size} />
})
export const UploadImage: FC<{ radiusUpload?: number | string; loadingImg?: boolean; type?: string; srcImg?: string }> = ({
  radiusUpload,
  loadingImg,
  type,
  srcImg
}) => {
  const { translate } = useTranslation()
  return (
    <div
      style={{ borderRadius: radiusUpload }}
      className={`${style['view-default-img']} custom-default-img ${style[type === 'company' ? 'square' : 'circle']}`}>
      {!srcImg ? (
        <>
          <CameraFilled className={style['icon-add-photo']} />
          <span className={style['icon-text']}>{translate('label:upload_image')}</span>
          {loadingImg && <Spin className="mr-2" spinning={true} />}
        </>
      ) : (
        <div className={style['edit-image']}>
          <LogoCompanyView src={srcImg} size={80} />
          {/* <img
            src={srcImg}
            alt="avatar"
            style={{ borderRadius: radiusUpload }}
            className={`w-100 object-cover ${style[type === 'company' ? 'square' : 'circle']}`}
          /> */}
          <div className={`${style['icon']} ${style[type === 'company' && 'icon-company']}`}>
            <Avatar style={{ color: '#000', backgroundColor: '#e8e8e8' }} size={24} icon={<CameraFilled />} />
          </div>
        </div>
      )}
    </div>
  )
}
export const UpdateLogoCompanyView: FC<{ src: string; loadingImg?: boolean }> = ({ src, loadingImg }) => {
  return (
    <div className={`${style['view-default-img']} ${style['square']}`}>
      {loadingImg ? (
        loadingImg && <Spin className="mr-2" spinning={true} />
      ) : (
        <div className={style['edit-image']}>
          <img src={src ? src : LOGO_DEFAULT} alt="avatar" className={`w-100 h-100 object-cover ${style['square']}`} />
          <div className={`${style['icon']} ${style['icon-company']}`}>
            <Avatar style={{ color: '#000', backgroundColor: '#e8e8e8' }} size={24} icon={<CameraFilled />} />
          </div>
        </div>
      )}
    </div>
  )
}
export const UpdateAvatarView: FC<{ src: string; loadingImg?: boolean }> = ({ src, loadingImg }) => {
  return (
    <div className={`${style['view-default-img']} ${style['circle']}`}>
      {loadingImg ? (
        loadingImg && <Spin className="mr-2" spinning={true} />
      ) : (
        <div className={style['edit-image']}>
          <img src={src ? src : AVATAR_DEFAULT} alt="avatar" className={`w-100 h-100 object-cover ${style['cricle']}`} />
          <div className={`${style['icon']} ${style['icon-company']}`}>
            <Avatar style={{ color: '#000', backgroundColor: '#e8e8e8' }} size={24} icon={<CameraFilled />} />
          </div>
        </div>
      )}
    </div>
  )
}
