/**
 * Login Page
 */
export const TRACKING_ID_LOGIN_LOGIN = 'login_form_submit'
export const TRACKING_ID_LOGIN_GOOGLE = 'login_form_google_login'
export const TRACKING_ID_LOGIN_FACEBOOK = 'login_form_facebook_login'

/**
 * Employer onboarding
 */

export const TRACKING_ID_EMPLOYER_ONBOARDING_SUBMIT_BUTTON = 'employee_onboarding_submit_button'

export const TRACKING_ID_HEADER_LOGIN = 'employee_header_login_button_1'
export const TRACKING_ID_POST_JOB_4_FREE_1 = 'employee_button_post_jobs_for_free_1_1'
export const TRACKING_ID_POST_JOB_4_FREE_2 = 'employee_button_post_jobs_for_free_2_1'
export const TRACKING_ID_POST_JOB_4_FREE_3 = 'employee_button_post_jobs_for_free_3_1'
export const TRACKING_ID_EMPLOYEER_REGISTER = 'employee_employee_regiter_submit_button_1'
