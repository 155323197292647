import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { Tooltip } from '../share/ANTD/Tooltip'

export const COMPANY_CHECKED = '/img/checked.svg'

const TooltipVerified = ({ title = '', width = 16, height = 16 }) => {
  const { translate } = useTranslation()
  return (
    <div className="tooltip">
      <Tooltip title={title || translate('page_companies:company_verify')}>
        <Image src={COMPANY_CHECKED} alt="company-verify" className="tooltip-company-verify" width={width} height={height} />
      </Tooltip>
    </div>
  )
}

export default TooltipVerified
