import { API_URL_LOGIN, API_URL_RESEND_EMAIL, API_URL_CANDIDATES_LOGIN_SOCIAL } from 'src/@legacy/utilities/APIConstant'
import Client from 'src/@legacy/utilities/Client'

export const submitLoginAPI = (values) => {
  return Client.post<any>(API_URL_LOGIN, { data: { email: values.email.toLowerCase(), password: values.password } })
}

export const submitResendEmailAPI = ({ email, user_id }) => {
  return Client.post(API_URL_RESEND_EMAIL, {
    data: {
      user_id: user_id,
      email: email
    }
  })
}

export const submitLoginWithSocialAPI = ({ token, provider }) => {
  return Client.post<any>(API_URL_CANDIDATES_LOGIN_SOCIAL, {
    sendUTM: true,
    data: { provider, token },
    preventRedirect: true
  })
}
