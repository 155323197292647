import client from '../../@core/utilities/Client'
import { API_URL_CANDIDATE_REGISTER, API_URL_REGISTER, API_URL_EMPLOYER_REGISTER_SOCIAL } from 'src/@legacy/utilities/APIConstant'

export const submitRegisterAPI = ({ values, fromProduct, currentLanguageNumber }) => {
  return client.post(API_URL_CANDIDATE_REGISTER, {
    config: { sendUTM: true },
    data: {
      full_name: values.fullname,
      email: values.email.toLowerCase(),
      phone_number: values.phone,
      password: values.password,
      from_product: fromProduct,
      language: currentLanguageNumber
    }
  })
}

export const submitRegisterSocialAPI = ({ values, fromProduct, currentLanguageNumber }) => {
  return client.post(API_URL_EMPLOYER_REGISTER_SOCIAL, {
    config: { sendUTM: true },
    data: {
      name: values.name,
      full_name: values.full_name,
      email: values.email.toLowerCase(),
      phone_number: values.phone,
      photo_url: values.photo_url,
      from_product: fromProduct,
      language: currentLanguageNumber,
      provider: values.provider
    }
  })
}

export const submitRegisterEmployerAPI = ({ values, fromProduct, currentLanguageNumber }) => {
  return client.post(API_URL_REGISTER, {
    config: { sendUTM: true },
    data: {
      name: values.name,
      full_name: values.full_name,
      email: values.email.toLowerCase(),
      phone_number: values.phone,
      password: values.password,
      from_product: fromProduct,
      language: currentLanguageNumber
    }
  })
}
