import css from 'styled-jsx/css'

export const RegisterSocialStyle = css.global`
  .register-social .register-info {
    margin-left: 16px;
  }
  .register-social .user-name {
    font-size: 18px;
    color: #262626;
    font-weight: 500;
    margin-bottom: 2px;
  }
  .register-social .email {
    font-size: 16px;
    color: #8c8c8c;
  }
  .register-social .content {
    color: #595959;
    font-size: 16px;
    margin: 16px 0 24px 0;
  }
  .register-social .btn-submit {
    margin-top: 8px;
    height: 48px;
  }
`
